import React from 'react';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';
import TermsandConditions from '../assets/files/Terms_and_Conditions.pdf';
import PrivacyPolicy from '../assets/files/Privacy_Policy.pdf';

const FooterBar = (props) => {
    const { image1, image2, vline } = props
    return (
        <footer>
            <Container>
                <Row className='align-items-center'>
                    <Col className="d-flex justify-content-start">
                        <img
                            src={image1}
                            alt="hm_image"
                            style={{ width: "10em", height: "auto" }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <img
                            src={image2}
                            alt="footer_image"
                            style={{ width: "3em", height: "auto" }}
                        />
                    </Col>
                </Row>
                <hr className="footer-divider" />
                <Row className='footer quicksand-normal-white'>
                    <Col className="d-flex justify-content-start">
                        <a href={TermsandConditions} className="footer-link" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href={PrivacyPolicy} className="footer-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href="/news" className="footer-link">News</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href="https://complaint.healthmoni.com/" className="footer-link" target="_blank" rel="noopener noreferrer">Complaints</a>
                    </Col> 
                </Row>
                <hr className="footer-divider" />
                <Row>
                    <Col className='quicksand-normal-white footer-padding'>
                    HealthMoni Limited is a distributor of Modulr FS Limited, a company registered in England and Wales 
                    with company number 09897919, which is authorised and regulated by the Financial Conduct Authority as 
                    an Electronic Money Institution (Firm Reference Number: 900573) for the issuance of electronic money and payment services. 
                    Your account and related payment services are provided by Modulr FS Limited. Whilst Electronic Money products are not covered 
                    by the Financial Services Compensation Scheme (FSCS) your funds will be held in one or more segregated accounts and the 
                    safeguarded in line with the Electronic Money Regulations 2011 - for more information please see <a href="https://www.modulrfinance.com/hubfs/Public%20one%20pagers/How%20we%20keep%20your%20money%20safe.pdf?utm_campaign=CS%20Newsletter&utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8GGUjvcSIumPYzdQZepftCuGJ6TYzh3IPcsG1gPqbq96LuNARG8ERWJHfA50Dk5GpjbW17" className="footer-link" target="_blank" rel="noopener noreferrer">this link</a>.
                    </Col>
                </Row>
                <Row>
                    <Col className='quicksand-normal-white footer-padding'>
                    HealthMoni Limited is registered in England No. 11522723. Registered Office:  128 City Road, London, United Kingdom, EC1V 2NX.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default FooterBar;